@media screen and (max-width: 768px) {
  // .userdet-container {
  //     flex-direction: column;
  //     align-items: center;
  //   }

  //   .user-detcont,
  //   .user-imgcont {
  //     width: 100% !important;
  //   }

  //   .tour-det-res {
  //     text-align: center;
  //   }

  //   .background-patch {
  //     font-size: 14px;
  //     padding: 5px 10px;
  //   }

  //   .img-box img {
  //     width: 70px;
  //     height: 70px;
  //   }
  .user-detcont {
    flex-direction: column !important;
    width: 100% !important;
    text-align: center !important;
  }

  .tour-det-res {
    flex-direction: column !important;
    text-align: center !important;
    align-items: center !important;
  }

  .card-image-div {
    width: 100% !important;
    padding: 0 !important;
  }

  .tour-card-body {
    width: 100% !important;
    padding: 10px !important;
    text-align: center !important;
  }

  .tour-days {
    justify-content: center !important;
    text-align: center !important;
    display: flex;
    // flex-direction: column;
  }

  .justify-content-div {
    justify-content: center !important;
    margin: auto !important;
  }

  .tour-days img {
    margin: 0 auto !important;
  }

  .tour-days span {
    display: block !important;
    text-align: center !important;
  }

  .background-patch {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .tour-text-align {
    text-align: center !important;
    width: 100%;
  }

  .destinations {
    justify-content: center !important;
  }

  //flex container for gender and food radios
  .flex-cont {
    display: flex;
    flex-direction: column;
    gap: 0 !important;
  }

  // responsive layout for main container
  .main-cont {
    width: 100%;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    //main container overflow fixed when hamburger menu is shown
    overflow-x: hidden !important;
  }

  // designs for banner image
  .banner-image {
    width: 100%;
    height: auto;
  }

  .icon-inputspace input,
  select {
    // position: relative !important;
    // padding-left: 30px !important;
    text-align: center;
  }

  //  .tour-det-res{
  //     display: flex !important;
  //     flex-direction: column !important;
  //  }
  //  .tour-days{
  //     justify-content: flex-start !important;
  //  }
  .banner-image img {
    width: 100%;
    object-fit: cover;
  }

  .booking-det {
    width: 100% !important;
  }

  //styles for the user details div- main div that contains all the remaining divs except banner image
  .userdet-div {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
  }

  //user details container that contains divs except booking details and price breakdown
  .userdet-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

  }

  //div that contains tour details
  .user-detcont {
    width: 100% !important;
    order: 1 !important;
    margin-bottom: 15px;
  }

  //user image and name container
  .user-imgcont {
    width: 100% !important;
    order: -1 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }

  .user-imgcont .img-box {
    width: 150px;
    height: 150px;
  }

  .sub-heading {
    font-size: 18px;
  }

  .block-letter {
    font-size: 16px;
  }

  //styles for sorm-control , input and select items
  .form-control,
  input,
  select {
    width: 100%;
    padding: 8px 12px !important;
    font-size: 14px;
  }

  .section-header {
    padding: 8px 12px;
    font-size: 16px;
  }

  .price-breakdown-row {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .price-breakdown-row .col {
    width: 100%;
    margin-bottom: 10px;
  }

  .btn {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
  }

  .smallsize-responsive {
    display: flex !important;
    flex-direction: column !important;
  }

  .booking-det {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .booking-det-col {
    width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;

  }
}

@media screen and (max-width: 480px) {
  .userdet-container {
    gap: 10px;
  }

  .sub-heading {
    font-size: 16px;
  }

  .form-control,
  input,
  select {
    font-size: 12px;
    padding: 6px 10px !important;
  }

}

@media (min-width:480px) and (max-width:768px) {
  .main-card-content {
    display: flex;
    justify-content: center !important;
    padding: 10px !important;
    gap: 30px !important;
  }

  .night-days-person {
    justify-content: end !important;
  }

  // .clock-icon {
  //   padding-top: 30px !important;
  // }
}