@media screen and (max-width:620px) {
    .card-components{
        width: 300px !important;
    }
}
@media screen and (max-width:375px) {
    .card-components{
        width: 250px !important;
    }
}
