@media screen and (max-width:768px) {
    .user-imgname{
        display: flex;
        flex-direction: column;
        gap: 0 !important;
        padding-bottom: 30px;
    }
   
    .img-box-container{
        display: flex;
        justify-content: center;
        margin: auto;
        flex-direction: column;
    }
    .user-nameid-container{
        display: flex;
        
        margin: auto;
    }
    .user-nameid{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-top: 2px !important;
        
    }
    #remove-under{
        display: block !important;
    }
    #remove-botom{
        display: none !important;
    }
    .chng-pass{
        display: none !important;
    }
    .chng-pass-bottom{
        display: block !important;
    }
    .gender-container{
        display: flex !important;
        flex-direction: column !important;
    }
}
@media (min-width:768px) {
    .chng-pass{
        display: block !important;
    }
    .chng-pass-bottom{
        display: none !important;
    }
   
    #remove-under{
        display: none !important;
    }
    #remove-botom{
        display: block !important;
    }
}
