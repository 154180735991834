// Animation for spinning effect
@keyframes spinner-animation {
    100% {
      transform: rotate(360deg);
    }
  }
  // Overlay to cover the entire screen when loading
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999;
  }
  // Spinner size and animation
  .loader-spinner {
    width: 80px;
    height: 80px;
    animation: spinner-animation 900ms linear infinite;
  }
  
  .loader-circle {
    stroke: #979797;
    stroke-opacity: 0.25;
  }
  
  // Animated rotating segment
  .loader-segment {
    stroke: #2e3a4b;
  }
  